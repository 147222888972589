<template>
  <div>
    <customerComponent ref='customerList' @on-create="create" />
  </div>
</template>

<script>
import customerComponent from '@/components/community/CustomerList'

export default {
  components: {
    customerComponent
  },
  methods: {
    handleChangeTag (val) {
      this.bottomTaskType = val
      if (val === 1) {
        this.currentComponent = 'customer'
      } else if (val === 2) {
        this.currentComponent = 'saler'
      }
    },
    reload () {
      this.$refs.customerList.reload()
    },
    create () {
      this.$emit('on-create')
    }
  }
}
</script>
