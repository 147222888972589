<style scoped>
.pull-right {
  float: right;
  text-align: right;
}
.ivu-form-item {
  margin-bottom: 5px !important;
}
</style>
<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="4" :md="4" :lg="4">
        <Select
          size="small"
          v-model="query.type"
          placeholder="客户类型"
          clearable
        >
          <Option value="4">一类公益</Option>
          <Option value="7">二类公益</Option>
          <Option value="8">三类公益</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="16" :lg="16">
        <i-input
          size="small"
          placeholder="客户名称"
          v-model="query.keyword"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="4" :md="2" :lg="2">
        <Button
          size="small"
          type="primary"
           icon="ios-search"
          @click="SearchCustomers"
          :long="true"
          >搜索</Button
        >
      </i-col>
      <i-col :xs="24" :sm="4" :md="2" :lg="2">
        <Button size="small" type="success" @click="CreateCustomer" :long="true"
          >新增客户</Button
        >
      </i-col>
    </Row>
    <div>
      <Table size="small" :columns="columndata" :data="tableData" stripe></Table>
      <div class="paging_style">
        <Page size="small"
          :total="total"
          :page-size="query.pageSize"
          show-total
          show-elevator
          :current="query.pageNumber"
          @on-change="changePage"
        ></Page>
      </div>
    </div>
    <Modal
      title="客户详情"
      v-model="customerDetailModal"
      width="1000"
      :footer-hide="true"
    >
      <Card>
        <div slot="title">
              <div class="workplatform-title">{{ advertiser.name }}</div>
        </div>
        <div>
          <Row>
            <i-col :xs="24" :sm="24" :md="24" :lg="24">
               <Row>
                <i-col span="8" ><span class="title">客户类型 </span >{{advertiser.typeName}}</i-col >
                <i-col span="8" ><span class="title">所属媒介主 </span >{{advertiser.publisherName}}</i-col >
                <i-col span="8" ><span class="title">联系电话 </span >{{advertiser.tel}}</i-col >
                <i-col span="8" ><span class="title">地址 </span > {{
                  (advertiser.provinceName === null
                    ? ""
                    : advertiser.provinceName) +
                  (advertiser.cityName === null ? "" : advertiser.cityName) +
                  (advertiser.areaName === null ? "" : advertiser.areaName) +
                  (advertiser.address === null ? "" : advertiser.address)
                }}</i-col >
                <i-col span="8"><span class="title">客户备注信息 </span>{{advertiser.description}}</i-col>
                <i-col span="8" ><span class="title">创建时间 </span >{{advertiser.createTime}}</i-col >
              </Row>

            </i-col>
          </Row>
          <Tabs>
            <TabPane label="联系人列表" name="contacts">
              <Row :gutter="8">
                <i-col
                  v-for="contact in contacts"
                  :key="contact.id"
                  :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="8"
                >
                  <Card>
                    <div class="pull-right">
                      <span
                        type="pull-right mr-2"
                        @click="confirmDeleteContact(contact.id)"
                      >
                        <Icon type="md-close" />
                      </span>
                    </div>
                    <div>
                      <div style="margin: 5px 0px 10px 15%">
                        <span
                          >{{ contact.department }}
                          {{ contact.position }}</span
                        >
                      </div>
                      <div style="margin: 0px 0px 10px 15%">
                        <span>{{ contact.name }}</span>
                      </div>
                      <div style="margin: 0px 0px 10px 15%">
                        <span>{{ contact.phone }}</span>
                      </div>
                      <div
                        class="pull-right text-right"
                        style="margin: 0px 5px 10px 30px"
                      >
                        {{ contact.createTime }}
                      </div>
                      <br />
                    </div>
                  </Card>
                </i-col>
                <i-col :xs="24" :sm="12" :md="8" :lg="8">
                  <Form
                    ref="contactData"
                    :model="contactData"
                    :rules="ruleValidate"
                    label-position="left"
                  >
                    <Card>
                      <Row :gutter="8">
                        <i-col span="12">
                          <FormItem prop="department">
                            <Input
                              v-model="contactData.department"
                              size="small"
                              placeholder="部门"
                            />
                          </FormItem>
                        </i-col>
                        <i-col span="12">
                          <FormItem prop="position">
                            <Input
                              v-model="contactData.position"
                              size="small"
                              placeholder="职务"
                            />
                          </FormItem>
                        </i-col>
                        <i-col span="16">
                          <FormItem prop="name">
                            <Input
                              v-model="contactData.name"
                              size="small"
                              placeholder="联系人姓名"
                            />
                          </FormItem>
                        </i-col>
                        <i-col span="16">
                          <FormItem prop="phone">
                            <Input
                              v-model="contactData.phone"
                              size="small"
                              placeholder="联系电话"
                            />
                          </FormItem>
                        </i-col>
                        <!-- <i-col span="4"></i-col> -->
                        <i-col span="8">
                          <FormItem>
                            <Button
                              type="success"
                              size="small"
                              @click="contactHandleSubmit('contactData')"
                              >新增联系人</Button
                            >
                          </FormItem>
                        </i-col>
                      </Row>
                      <Row> </Row>
                    </Card>
                  </Form>
                </i-col>
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import {
  getCommunityAdvertiserPage,
  getContactList,
  addContact,
  deleteContact
} from '@/api/crm/advertiser'

export default {
  name: 'OrderList',
  data () {
    return {
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        keyword: ''
      },
      tableData: [],
      columndata: [
        {
          title: '客户名称',
          key: 'name'
        },
        {
          title: '客户类型',
          key: 'typeName'
        },
        {
          title: '客户备注',
          key: 'description'
        },
        {
          title: '报备时间',
          key: 'createTime'
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          width: 150,
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  props: {
                    type: 'info',
                    size: 'small'
                  },
                  style: {
                    marginRight: '5px'
                  },
                  on: {
                    click: () => {
                      this.details(params)
                    }
                  }
                },
                '详情'
              )
            ])
          }
        }
      ],
      customerDetailModal: false,
      advertiser: '',
      contacts: [],
      contactData: {
        advertiserId: '',
        name: '',
        position: '',
        department: '',
        phone: '',
        userId: this.$store.getters.token.userInfo.userId
      },
      ruleValidate: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        position: [{ required: true, message: ' ', trigger: 'blur' }],
        department: [{ required: true, message: ' ', trigger: 'blur' }],
        phone: [{ required: true, message: ' ', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getPageData () {
      getCommunityAdvertiserPage(this.query).then((response) => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    reload () {
      this.getPageData()
    },
    SearchCustomers () {
      this.query.pageNumber = 1
      this.getPageData()
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.getPageData()
    },
    details (para) {
      this.advertiser = para.row
      this.initContacts()
      this.customerDetailModal = true
    },
    CreateCustomer () {
      this.$emit('on-create')
    },
    initContacts () {
      this.contacts = []
      const advertiserquery = {
        advertiserId: this.advertiser.id
      }
      getContactList(advertiserquery).then((res) => {
        this.contacts = res
      })
    },

    contactHandleSubmit (dataname) {
      this.$refs[dataname].validate((valid) => {
        if (valid) {
          this.contactData.advertiserId = this.advertiser.id
          addContact(this.contactData).then((res) => {
            if (res) {
              this.$refs[dataname].resetFields()
              this.initContacts()
            }
          })
        }
      })
    },
    confirmDeleteContact (val) {
      this.$Modal.confirm({
        title: '确定删除？',
        content: '<p>确定要删除该条数据，删除后将不可恢复！</p>',
        loading: true,
        onOk: () => {
          this.query.contactId = val
          this.deleteContact()
          this.$Modal.remove()
        }
      })
    },
    deleteContact () {
      deleteContact(this.query).then((res) => {
        if (res && !res.errcode) {
          this.initContacts()
          this.$Notice.success({ desc: '删除联系人成功。' })
        }
      })
    }
  },
  created () {
    this.getPageData()
  }
}
</script>
